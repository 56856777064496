import React from 'react';
import DevOpsConnectPageTemplate from '../../../components/Connect/Devops';

const subtitles = {
  A: (
    <>
      Tintash is a full service development company providing managed remote teams from around the globe. We partner with you to design and implement the DevOps practices, procedures and technologies that will drive your digital transformation.
    </>
  ),
  B:
    'Leverage our expert cloud DevOps consultants to elevate your project, helping you achieve the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <DevOpsConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Industry Leading Dev-Ops Company"
      subtitles={subtitles}
      metaTitle="Industry Leading Dev-Ops Company | Tintash"
      metaDescription="Tintash is an industry leading cloud development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      pageviewTitle="Connect/Cloud/Cloud.DevOps"
    />
  );
}

export default index;
