import React from 'react';
import Container from '../../../Shared/Container/Container';

function StateOfDevops() {
  return (
    <section className="connect__devops-section">
      <Container>
        <div className="connect__devops-heading">State of DevOps</div>
        <div className="connect__devops-stats">$​​10.31 B</div>
        <p className="connect__devops-description">
          The <span className="connect__devops-description-bold">DevOps </span>market is expected to
          grow from{' '}
          <span className="connect__devops-description-bold">
            USD 3.42 Billion in 2018 to USD 10.31 Billion by 2023
          </span>
          , at a Compound Annual Growth Rate{' '}
          <span className="connect__devops-description-bold">(CAGR) of 24.7%</span> during the
          forecast period.
          <br />
          <span className="connect__devops-gartner"> Gartner</span>
        </p>
      </Container>
    </section>
  );
}

export default StateOfDevops;
