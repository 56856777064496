import React, {useState} from 'react';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Container from '../../../Shared/Container/Container';
import DevopsSymbol from '../../../../assets/images/connect/devops/devopsSymbol.webp';
import Link from '../../../Shared/Link/Link';
import DevopsVerticalsCard from '../../Card/DevopsVerticals/DevopsVerticalsCard';

const TECHNOLOGIES = [
  {
    process: 'Plan',
    title: 'Plan',
    description: 'Enterprise software portfolio planning & Value Stream Management',
    logos: [
      {src: 'gitlab.webp', title: 'Gitlab'},
      {src: 'github.webp', title: 'Github'},
      {src: 'asana.webp', title: 'Asana'},
      {src: 'redmine.webp', title: 'Redmine'},
      {src: 'azure-boards.webp', title: 'Azure Boards'},
      {src: 'bitbucket.webp', title: 'Bitbucket'},
    ],
  },
  {
    process: 'Code',
    title: 'Code',
    description:
      'Design, develop and securely manage code and project data using Version Control Systems',
    logos: [
      {src: 'gitlab.webp', title: 'Gitlab'},
      {src: 'github.webp', title: 'Github'},
      {src: 'azure.webp', title: 'Azure Repos'},
      {src: 'bitbucket.webp', title: 'Bitbucket'},
    ],
  },
  {
    process: 'Build',
    title: 'Build (Continuous Integration)',
    description: 'Package applications and dependencies, manage containers, and build artifacts',
    logos: [
      {src: 'jenkins.webp', title: 'Jenkins'},
      {src: 'quey.webp', title: 'Quay'},
      {src: 'docker.webp', title: 'DockerHub'},
      {src: 'aws.webp', title: 'AWS ECR'},
      {src: 'gitlab.webp', title: 'Gitlab CI'},
      {src: 'github.webp', title: 'Github Actions'},
      {src: 'azure.webp', title: 'Azure Pipelines'},
      {src: 'aws.webp', title: 'AWS CodeBuild'},
    ],
  },
  {
    process: 'Test',
    title: 'Test (Code Quality and Security)',
    description:
      'Automated testing, Static Analysis Security Testing (SAST), Dynamic Analysis Security testing (DAST), vulnerability management and code quality analysis',
    logos: [
      {src: 'sonarqube.webp', title: 'SonarQube'},
      {src: 'clair.webp', title: 'Clair'},
      {src: 'splunk.webp', title: 'Splunk'},
      {src: 'anchore.webp', title: 'Anchore'},
      {src: 'snyk.webp', title: 'Snyk'},
      {src: 'rapid7.webp', title: 'Rapid7'},
      {src: 'azure.webp', title: 'Azure Test Plans'},
    ],
  },
  {
    process: 'Release',
    title: 'Release',
    description: 'Automate the release of applications: Artifact Management',
    logos: [
      {src: 'artifactory.webp', title: 'Artifactory'},
      {src: 'nexus.webp', title: 'Nexus'},
      {src: 'jenkins.webp', title: 'Jenkins'},
      {src: 'azure.webp', title: 'Azure Artifacts'},
    ],
  },
  {
    process: 'Deploy',
    title: 'Deploy (Continuous Deployment)',
    description: 'Automate the delivery of applications',
    logos: [
      {src: 'azure.webp', title: 'Azure DevOps'},
      {src: 'gitlab.webp', title: 'Gitlab'},
      {src: 'argo.webp', title: 'Argo'},
      {src: 'jenkins.webp', title: 'Jenkins'},
      {src: 'aws.webp', title: 'AWS CodeBuild'},
    ],
  },
  {
    process: 'Operate',
    title: 'Operate',
    description:
      'Configure and manage application environments; Infrastructure as Code, Configuration as Code, ChatOps, Secret Management, Kubernetes Cluster Management, Openshift Cluster Management',
    logos: [
      {src: 'ansible.webp', title: 'Ansible'},
      {src: 'chef.webp', title: 'Chef'},
      {src: 'pulumi.webp', title: 'Pulumi'},
      {src: 'kubernets.webp', title: 'Kubernetes'},
      {src: 'puppet.webp', title: 'Puppet'},
      {src: 'terraform.webp', title: 'Terraform'},
      {src: 'vault.webp', title: 'Vault'},
      {src: 'docker.webp', title: 'Docker'},
      {src: 'redhat.webp', title: 'Openshift'},
      {src: 'elastic.webp', title: 'Elastic Beanstalk'},
    ],
  },
  {
    process: 'Monitor',
    title: 'Continuous Monitoring',
    description: 'Deployment of tools to help you reduce the severity and frequency of incidents',
    logos: [
      {src: 'gitlab.webp', title: 'NewRelic'},
      {src: 'datadog.webp', title: 'Datadog'},
      {src: 'splunk.webp', title: 'Splunk'},
      {src: 'prometheus.webp', title: 'Prometheus'},
      {src: 'stack.webp', title: 'ELK Stack'},
      {src: 'grafana.webp', title: 'Grafana'},
      {src: 'dynatrace.webp', title: 'Dynatrace'},
      {src: 'rapid7.webp', title: 'InsightVM and InsightIDR'},
    ],
  },
];

const PROCESSES = ['Plan', 'Code', 'Build', 'Test', 'Release', 'Deploy', 'Operate', 'Monitor'];

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function DevopsDeliveryProcess() {
  const [activeProcess, setActiveProcess] = useState(0);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveProcess(newValue);
  };

  return (
    <Container className="connect__devops-delivery-section">
      <div className="connect__devops-delivery-heading">DevOps Delivery Process</div>
      <img
        className="connect__devops-delivery-img img-fluid"
        src={DevopsSymbol}
        alt="DevopsSymbol"
      />
      <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {PROCESSES.map((process, index) => (
              <Tab label={process} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {TECHNOLOGIES.map((tech, index) => {
          if (value === activeProcess) {
            return (
              <TabPanel value={value} index={index}>
                <DevopsVerticalsCard
                  key={index}
                  title={tech.title}
                  description={tech.description}
                  logos={tech.logos}
                />
              </TabPanel>
            );
          }
        })}
      </Box>
      <div className="connect__delivery-btn-container">
        <Link className="connect__delivery-btn" to="/contact" color="process" size="long">
          LET&apos;S GET STARTED
        </Link>
      </div>
    </Container>
  );
}

export default DevopsDeliveryProcess;
