import React from 'react';
import clsx from 'clsx';
import {Link as GatsbyLink} from 'gatsby';
import Link from '../../../Shared/Link/Link';
import tintashLogoSrc from '../../../../assets/images/connect/tintash-logo.webp';
import landingBG from '../../../../assets/images/connect/devops/landing-bg.webp';
import Container from '../../../Shared/Container/Container';
import {VARIANT_A_KEY, VARIANT_B_KEY} from '../../Constants';

function DevOpsHeadingSection({subtitles, title, variant}) {
  return (
    <section
      style={{backgroundImage: `url(${landingBG})`}}
      className={clsx({
        'connect__devops-heading-section-a': variant === 'A',
        'connect__heading-section': variant === 'B',
      })}
    >
      <Container>
        <div className="connect__heading-subsection">
          <GatsbyLink to="/" className="connect__heading-link">
            <img src={tintashLogoSrc} alt="Tintash Logo" />
          </GatsbyLink>
          <h1 className="connect__heading-subtitle">{title}</h1>

          {variant === 'B' && (
            <div className="connect__heading-title">
              Partner with Us and <span className="connect__heading-title--bold">Supercharge</span>{' '}
              Your <span className="connect__heading-title--bold">Growth</span>
            </div>
          )}
          {variant === 'A' && (
            <div className="connect__heading-title connect__heading-title--b">
              Continuous <span className="connect__heading-title--bold">Integration </span>
              and
              <br />
              <span className="connect__heading-title--bold"> Management </span> of your Products
            </div>
          )}

          <h2 className="connect__heading-description">
            {variant === 'A' && subtitles[VARIANT_A_KEY]}
            {variant === 'B' && subtitles[VARIANT_B_KEY]}
          </h2>
          <Link className="connect__heading-btn" to="/contact" color="round" size="long">
            {variant === 'A' && 'CONTACT US'}
            {variant === 'B' && 'Build Your Team Today'}
          </Link>
        </div>
      </Container>
    </section>
  );
}

export default DevOpsHeadingSection;
