import React from 'react';
import Container from '../../../Shared/Container/Container';
import DevopsTechnologiesCard from '../../Card/DevopsTechnologiesCard/DevopsTechnologiesCard';

const TECHNOLOGIES = [
  {
    title: 'Container Orchestration',
    logos: [
      {src: 'kubernets.webp', title: 'Kubernetes'},
      {src: 'redhat.webp', title: 'Redhat Openshift'},
      {src: 'rancher.webp', title: 'Rancher'},
      {src: 'docker.webp', title: 'Docker Swarm'},
    ],
  },
  {
    title: 'Cloud Solutions',
    logos: [
      {src: 'aws.webp', title: 'AWS'},
      {src: 'azure.webp', title: 'Azure'},
      {src: 'ibm.webp', title: 'IBM Cloud'},
      {src: 'gcp.webp', title: 'GCP'},
      {src: 'oracle.webp', title: 'Oracle Cloud'},
    ],
  },
];

function DevOpsExpertiseSection() {
  return (
    <section className="connect-web__expertise-section">
      <Container>
        <div className="connect-web__expertise-heading"> Our Expertise</div>
        <p className="connect-web__expertise-description">
          With a number of years of experience under the belt, our DevOps team is skilled and
          equipped to deliver leading solutions to your business.
        </p>
        <div className="row">
          {TECHNOLOGIES.map(tech => (
            <DevopsTechnologiesCard
              classN="col-12 col-md-6"
              key={tech.title}
              title={tech.title}
              logos={tech.logos}
            />
          ))}
        </div>
      </Container>
    </section>
  );
}

export default DevOpsExpertiseSection;
